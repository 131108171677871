import $, { extend } from 'jquery';
import React from 'react';
import {UserToken,getUserObjData} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  RespBkMthdMapp
} from '../function_lib/common_lib';
import {CallCMSMapMethod_CBack,btnSetColorCMS_CBack,RespBkMthdMappCMS} from '../function_lib/common_cms';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from '../user_pages/header';
import Footer from '../user_pages/footer';
import Option_Module from '../user_pages/option_module';
import LogoImg from '../assets/images/logo_p_small.png';
import defaultPic from '../assets/images/9.png';

import Comp_Cms_Data_Mapping from '../components/comp_cms_data_mapping';


import {CMS_Add_New} from '../components/cms_add_new';
import {CMS_Add_New_Ck} from '../components/cms_add_new_ck';
import {CMS_Edit_Data} from '../components/cms_edit_data';
import {CMS_Add_Header} from '../components/cms_add_header';
import {CMS_Edit_Header} from '../components/cms_edit_header';


let loginUser="";
let userObject="";
let companyName="";
let clsCmsData;

let rf="0";

let totalFileAllow=8;

let objImages = [];
let indexExisting=0;
let arrExistingFiles=[];

class Cms_Data extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      this.state={ stCmsData:[],stMenuList:[],stAllCompList:[],stEditData:{},
      stBNode:"",stPNode:"",stEditData:{},stHeaderEditData:[],stExMenuId:"0"};
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsCmsData=this;
      }
      resetFilters(){
        $("#ser_text").val("");
        clsCmsData.getCmsData();
      }
      okSuccMapping(data,msg){
        alert(data);
      }

      // file upload section
fileValidation(){
  
  let allowFile=true;
/*
  let imgCount=objImages.length;
let othFileCount=objOthFiles.length;


let vTotalCount=imgCount+othFileCount;

if(vTotalCount>=totalFileAllow){
alert("Only "+totalFileAllow+" to be allowed .");
allowFile=false;
}
*/
return allowFile;

}



rmFileProc(data,lsImages,thumbDiv){
  //alert(lsImages);
  if(lsImages==="" || lsImages==="0"){
    lsImages="lsImages"
  }
  if(thumbDiv==="" || thumbDiv==="0"){
    thumbDiv="ref_div_";
  }
  
  if(document.getElementById(thumbDiv+data)!=null){
    document.getElementById(thumbDiv+data).remove();
  }
  
  this.lcRemoveJSON(data,"image",lsImages,thumbDiv);
}
lcRemoveJSON(refKey,fType,lsImages,thumbDiv){
    var jsonP=JSON.parse($("#"+lsImages).val());
    var arrJson=jsonP.objs;
    for(var ai=0;ai<arrJson.length;ai++){
      if(arrJson[ai]["name"]===refKey){
        arrJson.splice(ai,1);
        $("#"+lsImages).val(JSON.stringify(jsonP));
        break;
      }
    }
    objImages=arrJson;
}

ImageThumbBackNew(flImg, flName,fObject,othInfo="",lsElement="",thumbnail="",isMultiple=false){
  //alert(isMultiple);
  if(!this.fileValidation()){
    return;
  }

  if(flImg==="-10"){
    alert(flName);
    return;
  }
  if(lsElement===""){
    lsElement="lsImages"
  }
  let currentObject=null;
  let thumDiv="ref_div_";
  if(fObject){
    currentObject=fObject[0];
    if(currentObject.hasAttribute("thumbnail_div")){
      thumDiv=currentObject.getAttribute("thumbnail_div");
    }

  }

  if(othInfo===""){
    othInfo="nm"+indexExisting+","+indexExisting;
  }
  indexExisting++;
  var arrNmValue=othInfo.split(',');
  //arrExistingFiles.push
  arrExistingFiles[arrNmValue[1]]=arrNmValue[0];
  var strSet = "<div id=\""+thumDiv+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-6 lsthumb image_thumb_parent\">" +
  "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\" param3=\""+thumDiv+"\" param2=\""+lsElement+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
    "<a class=\"gallery-item\" href=\""+flImg+"\"><img src=\"" + flImg + "\"/></a>" +
    "</div>";
    if(flImg.indexOf("data:video/webm")!==-1 || flImg.indexOf("data:video/mp4")!==-1){
     strSet= "<div id=\""+thumDiv+arrNmValue[1]+"\" class=\"col-lg-3 col-md-3 col-sm-3 col-12 lsthumb image_thumb_parent\">" +
      "<i class=\"fa fa-close icon-close _removeBtn"+indexExisting+"\"  param3=\""+thumDiv+"\" param2=\""+lsElement+"\" param1=\""+arrNmValue[1]+"\" aria-hidden=\"true\"></i>"+
        "<a class=\"gallery-item\" href=\""+flImg+"\"><video controls type=\"video/mp4\" src=\"" + flImg + "\"/></a>" +
        "</div>";
    }

  


    
  //$("#audios").val("{\"objs\":" + JSON.stringify(objAudio) + "}");
  
 // alert(objImages.length);

 let tmpObjImages=[];
 if($("#"+lsElement).val()!==""){
  if(isMultiple===true){
    //alert("i min multiple");
    tmpObjImages=JSON.parse($("#"+lsElement).val());
    tmpObjImages=tmpObjImages.objs;
  }
 }
 tmpObjImages.push({ name: arrNmValue[1], value: flImg });
 $("#"+lsElement).val("{\"objs\":" + JSON.stringify(tmpObjImages) + "}");
  
  //alert(tmpObjImages.length);
  //alert(strSet);
if(thumbnail===""){
  thumbnail="imgLibThumb";
}
//alert(isMultiple);
if(isMultiple===false){
  $("."+thumbnail).html(strSet);
}else{
  //alert("i m in append");
  $("."+thumbnail).append(strSet);
}

  
  
  if(fObject!==null){
    fObject.val('');
  }
//   $('.gallery-item').magnificPopup({
//     type: 'image',
//     gallery: {
//         enabled: true
//     }
// });
RespBkMthdMapp(clsCmsData, "_removeBtn"+indexExisting, "rmFileProc", "2");
  //imgLibThumb
}

// end of file upload section
     

      addNewMapping(frmName){
        btnSetColorCMS_CBack(this,frmName,"b_userEntryNew",function(data,msg){
         
           if(data==="001"){
            alert("Addedd Successfully");
           }else{
            alert("Unable to add , Kindly check all record and try again");
           }
      
          })
      }

      getMenuList(){
        let map=[];
        map.push({name:"curl_type",value:"getTreeHeaders"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getTreeHeaders";
        CallCMSMapMethod_CBack(clsCmsData,map,function(data,msg){
          //alert(data);
          //$("#tUL").html(data);
          //return;
            if(data!=="0" && data!==""){
              let jsData=JSON.parse(data);
              //alert(JSON.stringify(jsData.all_list));
              clsCmsData.setState({stMenuList:jsData.menus,stAllCompList:jsData.all_list})
             
              }
        });
      }

      permanentDeleteData(item){
       
      if(window.confirm("Please confirm, deleting data permanently..")){
        //alert(JSON.stringify(item));
        //return;
        let map=[];
        map.push({name:"curl_type",value:"directDeleteData"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"del_id",value:btoa(item.id)});
      
        map["res_func"]="callBackrrr";
        map["curl_type"]="directDeleteData";
        CallCMSMapMethod_CBack(clsCmsData,map,function(data,msg){
          //alert(data);
          if(data!=="0"){
            alert("deleted successfully")
            //let cbkData=JSON.parse(data);
            //alert("Unmapped Successfully");
           
          }else{
            alert("Unable to delete");
          }
        });
      }
  

      }

      setSelectedForMapp(item){
        clsCmsData.setState({stEditData:item});
      }
      setEditContent(data){
        this.setState({stEditData:data},()=>{
          //AjaxCallMethod(this,"okSuccCNOT");
          RespBkMthdMappCMS(clsCmsData,"_setbackparam","getParamData");
        });
        }

      getCmsData(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getManageCMSNew"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        
        map.push({name:"ser_menu",value:$("#ser_menu").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        
       
        map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getManageCMSNew";
        CallCMSMapMethod_CBack(clsCmsData,map,function(data,msg){
         
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                clsCmsData.setState({stCmsData:listData},()=>{
                  clsCmsData.forceUpdateHandler();
                });
              }
        });
      }
      okSucc(data,smg){
        if(data==="001"){
          //alert("record added successfully");
          Custom_Alert("Successful","Record Added Successfully",true);
          clsCmsData.getCmsData();
          $("#frmCreate").find("input[type=text]").val("");
        }else{
          //alert(smg);
          Custom_Alert("Not Working",smg,false);
        }
        // alert(data);
      }



      
componentDidMount(){
  $(".cleditor").summernote();    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);
this.getCmsData();
this.getMenuList();

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">


<div className='col-lg-3 col-md-3 col-sm-3 float-right'>
<div className="form-group">
         <div className="input-group">
            <select class="form-control form-control-sm" id="ser_menu" name="ser_menu">
                <option value={"0"}>-select-</option>
                {this.state.stMenuList.map((item,index)=>{
                    return <option value={btoa(item.id)}>{item.col1}</option>
                })}

            </select>
             </div>
         </div>
</div>


<div className='col-lg-3 col-md-3 col-sm-3 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" rf="#btnCatSearch" className="_setSearchKeyUp keyEvent_btn form-control form-control-sm" placeholder='search by title' />
             </div>
         
         </div>

</div>

<div className="col-lg-4 col-md-4 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
                 <div onClick={()=>this.getCmsData()}  style={{marginRight:"10px"}} id="btnCatSearch" name="btnCatSearch" className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>this.resetFilters()} style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Reset</div>
</div>

</div>
    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Data List</b></h3>
                
                {/* option of page sections */}
                <div class="input-group-prepend float-right">
                    <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown">
                      Create New
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" data-toggle="modal" data-target="#divAddHeader" href="javascript:void(0)">New Menu</a>
                      <a class="dropdown-item" data-toggle="modal" data-target="#divAddHeader" href="javascript:void(0)">New Section to Menu</a>
                      <a class="dropdown-item" data-toggle="modal" data-target="#divCmsAddNew" href="javascript:void(0)">Add New Data</a>
                      {/* <a class="dropdown-item" onClick={()=>this.setSelectedForMapp(item)} href="javascript:void(0)">Edit</a>
                      <a class="dropdown-item" onClick={()=>this.setSelectedForMapp(item)} data-toggle="modal" data-target="#divPtrInvoice" href="javascript:void(0)">Delete</a> */}
                     
                    </div>
                  </div>
                {/* end of page sections */}

              </div>
                <table id="tbData11" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Menu/Page</th>
                      <th>Title</th>
                      {/* <th>Desc.1</th>
                      <th>Desc.2</th> */}
                      <th>Page link</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stCmsData.map((item,index)=>{
                       return(<tr>
                        <td>{index+1}</td>
                    
                  <td>{item.menu}</td>
                  <td>{item.col1}</td>
                  {/* <td>{item.col2}</td>
                  <td>{item.col3}</td> */}
                  <td>{item.col7}</td>
                  <td>
                  <div class="input-group-prepend">
                    <button type="button" class="btn btn-danger dropdown-toggle" data-toggle="dropdown">
                      Action
                    </button>
                    <div class="dropdown-menu">
                      <a class="dropdown-item" onClick={()=>this.setSelectedForMapp(item)} data-toggle="modal" data-target="#divCmsDataMapp" href="javascript:void(0)">Mapp to Other Page</a>
                      <a class="dropdown-item" data-toggle="modal" data-target="#divCmsEditNew" onClick={()=>this.setEditContent(item)} href="javascript:void(0)">Edit</a>
                     {/* <a class="dropdown-item" onClick={()=>this.setSelectedForMapp(item)} data-toggle="modal" data-target="#divPtrInvoice" href="javascript:void(0)">Delete</a> */}
                     <a class="dropdown-item" onClick={()=>this.permanentDeleteData(item)} href="javascript:void(0)">Delete Permanently</a>
                     
                    </div>
                  </div>
                  </td>
                  
                    </tr>)
                  }))}
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<Comp_Cms_Data_Mapping user_object={userObject} listMenus={this.state.stMenuList}
listAllComp={this.state.stAllCompList}
editData={this.state.stEditData}
pAddNewMapping={this.addNewMapping}
 />

<CMS_Add_New_Ck 
b_node={this.state.stBNode} p_node={this.state.stPNode} 
listMenus={this.state.stMenuList}
listAllComp={this.state.stAllCompList}
/>
{/* <CMS_Add_New b_node={this.state.stBNode} p_node={this.state.stPNode} 
listMenus={this.state.stMenuList}
listAllComp={this.state.stAllCompList}
/> */}

<CMS_Edit_Data b_node={this.state.stBNode} p_node={this.state.stPNode} editData={this.state.stEditData} />
<CMS_Add_Header user_object={userObject} menus={this.state.stMenuList} exmenuid={this.state.stExMenuId} addNewHeader={this.addNewHeader}/>
<CMS_Edit_Header user_object={userObject} menus={this.state.stMenuList} editHeaderData={this.state.stHeaderEditData} editHeader={this.editHeader} /> 


    {/* <Wa_Credit_Balance_Comp creditUser={this.state.crediUser} creditBalance={this.state.creditBalance} masterBalance={this.state.masterBalance} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default Cms_Data;