import $ from 'jquery';
import React,{useState} from 'react';
import {GetImgCertUserCMS_CBack,CallCMSMapMethod_CBack} from '../function_lib/common_cms';
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import {GetAxoisView} from '../function_lib/common_axios';

export function addCMSContent(){
  GetImgCertUserCMS_CBack(this,"#frmCmsAddNew","addNewContentCMS",function(checkData,checkMsg,userMsg){
      if(checkData===-10){
        alert("Kindly fill required fields");
        return;
      }else {
        //Custom_Alert("Successfully Added",checkMsg,false);
        alert(checkMsg);
        $('#divCmsAddNew').modal('hide');
        window.location.reload();
      }
    });
}             
  
export const CMS_Add_New_Ck=(props)=>{
  //alert(JSON.stringify(props.listMenus));
  const [getCompList,setCompList]=useState([]);
  const [getCompData,setCompData]=useState([]);
  //const [content, setContent] = useState('');

  const handleMapMenuChg=(event)=>{
        let tarValue=event.target.value;
        if(tarValue!=="0" && tarValue!==0){
            tarValue=atob(tarValue);
        }
        //alert(JSON.stringify(props.listAllComp));
        const listFltr = d =>d.parent_id===tarValue;
        let listComps=props.listAllComp.filter(listFltr);
        setCompList(listComps);
        funcGetCompData();
    }

    const funcGetCompData=()=>{
//alert(param1);
let map=[];
map.push({name:"curl_type",value:"getShortContentList"});
map.push({name:"res_func",value:"callBackrrr"});
map.push({name:"local_token",value:props.app_user_id});
map.push({name:"user_type",value:"20"});
map.push({name:"upper_id",value:props.app_user_id});

map.push({name:"menu_add_id",value:$("#menu_add_id").val()});
map.push({name:"comp_add_id",value:$("#comp_add_id").val()});


map["res_func"]="callBackrrr";
map["curl_type"]="getShortContentList";
CallCMSMapMethod_CBack(this,map,function(data,msg){
 if(data!=="0" || data!==0){
   let listData=JSON.parse(data);
   setCompData(listData);
 }
 // alert(data);
})
    }

    const handleCompChange=(event)=>{
 
      funcGetCompData();
    }
    //alert(JSON.stringify(props));
        return(<div className="modal fade bd-example-modal-xl"  id="divCmsAddNew" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
          <div className="modal-header bg-primary">
              <h5 className="modal-title text-white" id="exampleModalLabel">Add New</h5>
              <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" className="text-white">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            <form class="form-group" id="frmCmsAddNew" name="frmCmsAddNew" enctype="multipart/form-data" method="POST" onsubmit="event.preventDefault()">
                            <input type="hidden" name="res_func" id="res_func" value="okSucc" />
                            <input type="hidden" name="curl_type" id="curl_type" value="addNewContentCMS" />
                            <input type="hidden" name="m_type" id="m_type" value="addNewContentCMS" />
                            {/* <input type="hidden" name="product_id" id="product_id" value={typeof props.editProduct.rf_id!=="undefined"?props.editProduct.rf_id:""} /> */}
                            <input type="hidden" name="acc_inf_id" id="acc_inf_id" value={props.app_user_id}/>
                            <input type="hidden" name="lsImages" id="lsImages" />
                            <input type="hidden" name="lsOthImages" id="lsOthImages"/>
                            <input type="hidden" name="bNode" id="bNode" value={props.b_node}/>
                            <input type="hidden" name="pNode" id="pNode" value={props.p_node}/>
                            <input type="hidden" name="col20" id="col20" value={props.p_node}/>
                            <input type="hidden" name="type" id="type" value={"20"}/>
                          
                            <div class="card-body">
                                <div className="row">
                                  {((props.b_node==="" || props.p_node==="") && typeof props.listMenus!=="undefined")?<>
                                    <div className="col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Menu</label>
                                            <div className="input-group input-group-sm">
                                                <select id="menu_add_id" name="menu_add_id" onChange={handleMapMenuChg} class="form-control form-control-sm">
                                                <option value="0">-Menu-</option>
                                                        {props.listMenus.map((item,index)=>{
                                                          return (<option value={btoa(item.id)}>{item.col1}</option>)
                                                        })}

                                                </select>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Page Section</label>
                                            <div className="input-group input-group-sm">
                                                <select id="comp_add_id" name="comp_add_id" onChange={handleCompChange} class="form-control form-control-sm">
                                                <option value="0">-Component-</option>
                                                        {getCompList.map((item,index)=>{
                                                          return (<option value={btoa(item.id)}>{item.col1}</option>)
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                </div>
                                <div className="col-md-6 col-lg-6">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Select Parent</label>
                                            <div className="input-group input-group-sm">
                                                <select id="col19" name="col19" class="form-control form-control-sm">
                                                <option value="0">-Select-</option>
                                                        {getCompData.map((item,index)=>{
                                                          return (<option value={btoa(item.id)}>{item.col1}</option>)
                                                        })}
                                                </select>
                                            </div>
                                        </div>
                                </div>
                                  </>:''}
                                
                                <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Title</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="col1" name="col1" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                    
                                </div>
                                <div class="row">
                                 
    <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                            <label for="exampleInputEmail1">Description 1 *</label>

                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="col2" name="col2"></textarea>
                                            </div>
                                        </div>
                                    </div>
                              
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Description 2 *</label>
                                            <div className="input-group input-group-sm">
                                            <textarea className='textarea_editor cleditor form-control sptext2' cols="50" rows="10" id="col3" name="col3"></textarea>
                                            </div>
                                        </div>
                                    </div>

{/* image upload section */}
<div className="col-md-12">
<div className="row">
{/* file image upload */}

<div className="col-md-4">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Main Image </h3><small> (Max File Size 1MB, Only 1 will allow) </small>
</div>
<div className="card-body">
<div className="row audioLibThumb imgLibThumb">
                      
                      </div>
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBackNew" thumbnail_div={"main_img_div"} lsElement={"lsImages"} thumbnail={"imgLibThumb"} name="customFileBr" id="customFileBr" accept="image/*" size={100000000} className="updImgThumb" />
      <a href="javascript:void(0)" setTo="customFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
</div>

                               {/* end of file image upload */}

                               {/* file other image upload */}

<div className="col-md-8">
 <div className="card card-info">
<div className="card-header">
  <h3 className="card-title">Other Images </h3><small> (Max File Size 1MB, Only 7 will allow) </small>
</div>
<div className="card-body">
<div className="row audioLibThumb imgOthLibThumb">
                      
                      </div>
  <div className="row">
    
    <div className="col-12">
    <input type="file" ref_img="ImageThumbBackNew" thumbnail_div={"oth_img_div"} lsElement={"lsOthImages"} thumbnail={"imgOthLibThumb"} multiple name="customOthFileBr" id="customOthFileBr" accept="image/*" size={100000000} className="updImgThumb" />
      <a href="javascript:void(0)" setTo="customOthFileBr" className="_brFileThumb updthumb">Click & Browse Image Files</a>
    </div>
   
   
  </div>
</div>

</div>
</div>

                               {/* end of other file image upload */}
</div>
</div>
{/* end of Image Upload Section */}



                                    <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Video Links</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="col6" name="col6" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>

                                  <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Page Url</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="col7" name="col7" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-8 col-md-8">
                                        <div className="form-group">
                                            <label for="exampleInputEmail1">Url Text</label>
                                            <div className="input-group input-group-sm">
                                                <input type="text" id="col8" name="col8" className="form-control required11" placeholder="" />
                                            </div>
                                        </div>
                                  </div>

                                </div>
                                 
                                <div className="col-md-6 respSSL"></div>
                                <div id="testing2dd2222"></div>
                                <div class="row">
                                    <div class="col-12">
                                      
                                        <div class="form-group">
                                        <center>
                                            <div class="input-group input-group-sm" style={{"justify-content":"center"}}>
                                                <button type="button" onClick={()=>addCMSContent()} class="btn btn-primary">Submit</button>
                                            </div>
                                        </center>
                                        </div>
                                        
                                    </div>
                                </div>
                                </div>
                            </form>
          </div>
          </div>
          </div>
          </div>);
    
}
export default CMS_Add_New_Ck;
